.grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr 150px 150px;
    column-gap: 20px;
    padding-bottom: 30px;
}

.button_holder {
    padding-top: 20px;
}

:global span,
:global input,
:global button {
    font-family: Gotham-Medium !important;
}

.paper {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-bottom: 20px;
}

.textfield {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.password {
    margin-bottom: 25px;
}

.avatar {
    margin: 10px;
}

.form {
    width: 100%;
}

@import "../easyui.scss";

.date_picker_holder {
    :global .ant-picker {
        background-color: $light-grey;
        border: 2px solid $light-grey;
    }

    :global .ant-picker svg {
        fill: black;
    }

    :global .ant-picker.ant-picker-focused {
        background-color: white;
        border: 2px solid black !important;
        box-shadow: none !important;
    }
}

.calendar {
    :global
        .ant-picker-cell-in-view.ant-picker-cell-selected
        .ant-picker-cell-inner {
        background-color: $ck-orange;
    }

    :global .ant-btn-primary {
        background-color: $ck-orange;
    }

    :global .ant-picker-now-btn {
        color: $ck-orange;
    }

    :global
        .ant-picker-time-panel-column
        > li.ant-picker-time-panel-cell-selected
        .ant-picker-time-panel-cell-inner {
        background-color: rgba(237, 139, 0, 0.25);
    }

    :global
        .ant-picker-cell-in-view.ant-picker-cell-today
        .ant-picker-cell-inner::before {
        border-color: $ck-orange;
    }
}

.row:hover {
    cursor: pointer;
}

.icon {
    margin-left: 3px;
    position: absolute;
}

.icon_container {
    position: relative;
}

:global thead tr th {
    font-family: Gotham-Medium !important;
}

* {
    box-sizing: border-box;
}

.section_title {
    display: flex;
    font-weight: 700;
    font-size: 1.1em;
    margin-bottom: 20px;
}

.info_container {
    margin-bottom: 10px;
}

.tabs {
    position: fixed;
    width: calc(100% - 100px);
    max-width: 1250px;
    background-color: white;
    z-index: 100;
}

.tabs_buffer {
    height: 60px;
}

.push_message {
    margin-bottom: 20px;
}

.link_icon {
    position: absolute;
}

.icon_container {
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
}

.tip {
    margin-top: 30px;
    font-size: 12px;
}

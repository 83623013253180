@import '../../easyui-components/easyui.scss';

.loader_container {
    box-sizing: border-box;
    height: calc(100% - 96px - 52px);
    text-align: center;
    padding-top: 30vh;
}

.backdrop {
    z-index: 9999 !important;
}

.top_content {
    padding: 20px 20px 0;
}

:global .MuiCircularProgress-colorPrimary {
    color: $ck-red !important;
}

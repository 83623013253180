body {
    margin: 0;
    font-family: Gotham-Medium, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-height: 100%;
    background-color: white !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}


@font-face {
    src: url("./fonts/gotham_black.ttf");
    font-family: Gotham-Black;
}

@font-face {
    src: url("./fonts/gotham_bold.ttf");
    font-family: Gotham-Bold;
}

@font-face {
    src: url("./fonts/gotham_book.ttf");
    font-family: Gotham-Book;
}

@font-face {
    src: url("./fonts/gotham_book_italic.ttf");
    font-family: Gotham-Book-Italic;
}

@font-face {
    src: url("./fonts/gotham_medium.ttf");
    font-family: Gotham-Medium;
}

@font-face {
    src: url("./fonts/gotham_ultra.woff");
    font-family: Gotham-Ultra;
}

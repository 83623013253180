.info_container {
    margin-bottom: 10px;
    position: relative;
}

.title {
    margin-bottom: 5px;
    font-weight: 700;
    display: inline-block;
}

.icon {
    position: absolute;
    top: -4px;
    display: inline-block;
}

.icon_buffer {
    display: inline-block;
    width: 28px;
}

.info {
    margin-bottom: 10px;
}

.alert {
    margin-bottom: 10px;
    color: red;
}

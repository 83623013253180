@import "../easyui.scss";

$transition-time: 0.2s;

@mixin button {
    color: white;
    padding: 6px 12px;
    background-color: $ck-red;
    border: 2px solid $ck-red;
    display: inline-block;
    outline: none;
    cursor: pointer;
    transition: all $transition-time;
    display: inline-flex;
    gap: 6px;
    align-items: center;

    svg {
        fill: white;
        transition: all $transition-time;
    }

    &:hover {
        background-color: $ck-dark-red;
        border: 2px solid $ck-dark-red;
    }

    &:active {
        background-color: $ck-dark-red;
        border: 2px solid $ck-dark-red;
        box-shadow: 0 0 0 0.2rem rgba(224, 73, 62, 0.5);
    }

    &:disabled {
        background-color: $light-grey;
        border: 2px solid transparent;
        cursor: not-allowed;
        color: $gray;
    }
}

@mixin secondary-button {
    padding: 6px 12px;
    outline: none;
    cursor: pointer;
    transition: all $transition-time;
    display: inline-flex;
    gap: 6px;
    align-items: center;
    background-color: white;
    border: 2px solid black;
    color: black;

    svg {
        fill: black;
        transition: all $transition-time;
    }

    &:hover {
        background-color: black;
        border: 2px solid black;
        color: white;
        svg {
            fill: white;
        }
    }

    &:active {
        box-shadow: 0 0 0 0.2rem rgba(127, 127, 127, 0.5);
        background-color: #7f7f7f;
        border-color: #7f7f7f;
        color: white;
        svg {
            fill: white;
        }
    }
}

.button {
    @include button;
    &.secondary {
        @include secondary-button;
    }
}
